<template>
  <div>
    <div v-if="notFound">
      <v-alert
        text
        color="grey darken-2"
      >
        {{ $t('mediaItemNotFound') }}
      </v-alert>
    </div>
  <div class="media-preview" v-if="mediaItem">

    <v-hover v-slot="{ hover }">
      <v-card
        :flat="flat"
        :elevation="hover && canHover ? 12 : 0"
        :class="{ 'on-hover': hover && canHover }"
        :max-height="height"
        :max-width="width"
        :width="width"
        @click.stop="dialog = true"
      >
        <media-thumbnail
          v-if="mediaItem.dataUrl"
          v-model="mediaItem"
          :height="height"
          :width="width"
          :max-height="maxHeight"
        />
      </v-card>
    </v-hover>

    <v-dialog v-model="dialog" v-if="dialog" fullscreen>
      <v-card height="100%">
        <v-toolbar color="primary" flat dark>
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ fullValue.name }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="downloadOriginal">
            <v-icon >mdi-download</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text class="mx-auto">
          <v-row
            v-if="!fullValue.dataUrl"
            class="fill-height ma-8"
            justify="center"
          >
            <v-progress-circular
              indeterminate
              color="grey lighten-2"
              size="60"
            ></v-progress-circular>
          </v-row>
          <component v-if="fullValue.dataUrl" :is="viewerComponentType" :value="fullValue" />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import legacyMediaApi from '@/services/api/media'
import mediaApiBuilder from '@/services/api/media-library'
import mediaThumbnail from './media-thumbnail'
import { getComponentForContentType } from '@/services/media.service'

export default {
  components: {
    mediaThumbnail
  },
  props: {
    canHover: {
      type: Boolean,
      default: true
    },
    flat: {
      type: Boolean,
      default: false
    },
    height: {
      type: Number,
      default: 150
    },
    maxHeight: {
      type: Number,
      default: 150
    },
    width: {
      default: '100%'
    },
    id: {type: String, required: true}
  },
  data() {
    return {
      dialog: false,
      mediaItem: null,
      mediaApi: null,
      notFound: false,
      fullValue: {}
    }
  },
  computed: {
    ...mapGetters('user', ['tenantId']),
    viewerComponentType() {
      return getComponentForContentType(this.mediaItem.contentType, 'viewer')
    }
  },
  watch: {
    id(value){
      this.loadImage(value)
    },
    dialog(value){
      if(value && this.mediaItem.id){
        this.loadFullImage(this.mediaItem.id)
      }
    }
  },
  mounted() {
    this.mediaApi = mediaApiBuilder.build(this.tenantId)
    this.loadImage(this.id)
  },
  methods: {
    async loadImage(itemId) {
      try{
        const response = await this.mediaApi.meta(itemId)
        this.mediaItem = response.data

        const base64Response = await legacyMediaApi.getBase64Url(itemId, { size: 'medium' })
        if(base64Response){
          this.$set(this.mediaItem, 'dataUrl', base64Response)
        }
      }
      catch(error){
        if(error.status == 404){
          this.notFound = true
        }
      }
    },
    async loadFullImage(itemId, size){
      size = size || 'large'

      const response = await legacyMediaApi.getBase64Url(itemId, { size })
      if (response) {
        this.$set(this.fullValue, 'dataUrl', response)
        this.$set(this.fullValue, 'name', this.mediaItem.name || this.mediaItem.fileName)
      }
    },
    view() {
      window.open(this.dataUrl, '_blank')
    },
    async downloadOriginal() {
      try {
        const url = await legacyMediaApi.getDownloadUrl(this.mediaItem.id)
        window.open(url.url)
      } catch (error) {
        console.log('error', error)
      }
    }
  }
}
</script>

<style>
.media-preview .on-hover {
  cursor: pointer;
}
</style>

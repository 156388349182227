<template>
  <div class="media-thumbnail" v-if="dataUrl" v-bind:style="containerStyle">
    <v-card :loading="loading" class="mx-auto transparent" flat>
      <template slot="progress">
        <v-progress-linear
          color="primary"
          height="10"
          indeterminate
        ></v-progress-linear>
      </template>
      <template>
        <v-img
          :src="innerValue.dataUrl"
          :contain="contain"
          :max-height="maxHeight"
        >
          <template v-slot:placeholder>
            <v-row class="fill-height fill-width ma-0" justify="center">
              <v-progress-circular
                indeterminate
                color="grey lighten-5"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </template>
    </v-card>
  </div>
</template>

<script>
import mediaApi from '@/services/api/media'

export default {
  props: {
    contain: {
      type: Boolean,
      default: false
    },
    value: {
      type: null,
      default: ''
    },
    maxHeight: {
      type: String,
      default: '100%'
    },
    maxWidth: {
      type: String,
      default: '100%'
    }
  },
  data() {
    return {
      innerValue: '',
      loading: false
    }
  },
  computed: {
    dataUrl() {
      if (this.innerValue.dataUrl) {
        return this.innerValue.dataUrl
      }
      return null
    },
    containerStyle() {
      return {
        'max-height': this.maxHeight,
        'max-width': this.maxWidth
      }
    }
  },
  watch: {
    innerValue(value) {
      if (!value.dataUrl) {
        this.loadImage(value.id)
        this.$emit('input', value)
      }
    },
    value(val) {
      if (val != this.innerValue) {
        this.innerValue = val
      }
    }
  },
  methods: {
    async loadImage(itemId) {
      this.loading = true
      const response = await mediaApi.getBase64Url(itemId)
      if (response) {
        this.$set(this.innerValue, 'dataUrl', response)
      }
      this.loading = false
    }
  },
  mounted() {},
  created() {
    if (this.value) {
      this.innerValue = this.value
    }
  }
}
</script>

<style></style>

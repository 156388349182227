<template>
  <v-dialog v-model="dialog" width="75%" persistent>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        plain
        :color="color"
        v-bind="attrs"
        v-on="on"
        @click="dialog = true"
        >
        <slot>
          <v-icon left>mdi-attachment</v-icon>
          {{ $t('addAttachment') }}
        </slot>
      </v-btn>
    </template>
    <v-card class="pb-4">
      <v-toolbar flat dark color="primary">
        <v-toolbar-title>{{ $t('mediaAdd') }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn icon dark @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <div class="mb-6 text-center">
        <div v-if="files.length === 0" class="pa-2">
        </div>
        <v-row align-content="center" class="pa-4">
          <template v-for="(media, i) in files">
            <v-col :key="i" md="2" sm="4">
              <v-card height="100%" width="100%" class="card-bottom-actions">
                <media-preview :value="media" :height="150"></media-preview>
              </v-card>
            </v-col>
          </template>
        </v-row>
      </div>

      <div class="ma-4">
        <media-upload
          :tenantId="tenantId"
          :entityId="entityId"
          :entityType="entityType"
          @uploaded="onFilesUploaded"
          :accept="accept"
        ></media-upload>
      </div>
      <v-spacer />
    </v-card>
  </v-dialog>
</template>

<script>
import { ACCEPTED_FILE_TYPES } from '@/constants'
import mediaUpload from '@/components/media/media-upload2'
import mediaPreview from '@/components/media/media-preview'

export default {
  components: { mediaPreview, mediaUpload },
  props: {
    tenantId: { type: String, required: true },
    entityId: { type: String, required: false },
    entityType: { type: String, required: false },
    accept: { type: String, default: () => ACCEPTED_FILE_TYPES },
    color: { type: String, default: 'secondary' }
  },
  data() {
    return {
      dialog: false,
      files: []
    }
  },
  watch: {
    dialog(val) {
      if (!val) {
        this.files = []
      }
    }
  },
  methods: {
    close() {
      this.dialog = false
    },
    onFilesUploaded(files) {
      console.log('uploadDialog: onFilesUploaded', files)
      this.files.push(...files)
      this.$emit('media-added', files)
      this.dialog = false
    }
  }
}
</script>

<template>
  <v-card flat width-full rounded class="mb-2 pa-1" :class="cardClasses">
    <div class="d-flex flex-row flex-wrap justify-between">
      <user-avatar :id="message.createdByUserId" class="pr-2" />
      <span class="text--secondary text-no-wrapp">{{
        message.createdDateTime | dateFormat
      }}</span>
    </div>
    <v-card-text class="py-2">
      <div v-if="message.content" v-html="prettyContent"></div>

      <v-row v-if="hasMedia" dense no-gutters style="max-width:500px">
        <v-col v-for="attachment in message.attachments" :key="attachment.id" cols="4" class="pb-2">
          <div v-if="!attachment.isDeleted" style="max-width:300px">
            <media-preview :id="attachment.mediaItemId" width="150px"/>
          </div>
          <div v-if="attachment.isDeleted" style="max-width:300px">
            {{ $t('mediaItemHasBeenDeleted')}}
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { marked } from 'marked'
import { mapGetters } from 'vuex'
import UserAvatar from '@/components/member/avatar.vue'
import MediaPreview from '@/components/media/media-preview2.vue'

import xss from 'xss'

export default {
  components: {
    MediaPreview,
    UserAvatar
  },
  props: {
    message: {
      type: Object,
      required: true
    }
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters('user', ['userId']),
    cardClasses() {
      return this.message.createdByUserId == this.userId
        ? 'ml-10 green lighten-4'
        : 'mr-10 grey lighten-4'
    },
    prettyContent() {
      const markdown = marked.parse(this.message.content)
      return xss(markdown)
    },
    hasMedia(){
      return this.message.attachments.length > 0
    },
    mediaItems(){
      return this.message.attachments.map(attachment => {
        return { id: attachment.mediaItemId, name: attachment.title }
      })
    }
  },
}
</script>

<template>
  <v-card
      flat
      tile
      cols="1"
      style="min-width: 250px; max-width: 340px;"
      class="flex-grow-0 flex-shrink-0 border-right-1"
      color="grey lighten-4"
    >
      <v-app-bar
        dense
        elevation="1" >
        <v-app-bar-title class="text-small">{{ $t("messages") }}</v-app-bar-title>
        <v-spacer></v-spacer>
        <add-channel @save="onChannelAdded" />
      </v-app-bar>
      <v-list
        nav
        dense
        color="grey lighten-4"
      >
        <v-list-item-group
          v-model="group"
          active-class="text--accent-4"
          dense
          style="height:75vh;overflow-y: auto;"
        >
        <v-list-item v-if="sortedChannels.length === 0">
          <v-list-item-content>
            <v-list-item-title>{{ $t('noChannels') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          v-for="channel in sortedChannels"
          class="pl-4"
          :key="channel.id"
          @click="selectedChannel = channel"
          :to="{ name: 'messaging-channel-detail', params: { channelId: channel.id } }"
        >

          <v-list-item-content>
            <v-list-item-title>{{ channel.name| truncate(30, '...') }}
              <v-badge
                v-if="channel.unreadMessageCount > 0"
                bordered
                color="error"
                :content="channel.unreadMessageCount"
                class="pl-1" >
              </v-badge>
            </v-list-item-title>
            <v-list-item-subtitle>{{ getLastMessageDateTime(channel) }}</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action v-if="channel.entityType == 'collection'">
            <v-icon small>mdi-folder-table</v-icon>
          </v-list-item-action>
        </v-list-item>
        </v-list-item-group>


      </v-list>
    </v-card>


</template>

<script>
import _ from 'lodash'
import { mapGetters } from 'vuex'
import { format } from '@/services/datetime'
import profileMixin from '@/components/mixins/profile'
import AddChannel from '@/messaging/components/channel/add-channel.vue'

export default {
  mixins: [profileMixin],
  components: {
    AddChannel
  },
  data() {
    return {
      group: null,
      selectedChannel: null,
      selectedChannelIndex: null,
      message: ''
    }
  },
  computed: {
    ...mapGetters({ channels: 'messaging/channels'}),
    ...mapGetters('messaging', ['unreadMessageCount', 'unreadMessages']),

    sortedChannels(){
      return this.channels.filter(c => !c.entityType || c.entityType === 'channel' || c.entityType === 'collection').sort((a, b) =>  {
        if(!a.lastMessageDateTime) return 1;
        if(!b.lastMessageDateTime) return -1;

        return new Date(b.lastMessageDateTime) - new Date(a.lastMessageDateTime)
      })
    },
    messages() {
      return this.selectedChannel
        ? [...this.selectedChannel.messages].sort(
          (a, b) => new Date(a.createdDateTime) - new Date(b.createdDateTime)
        )
        : []
    }
  },
  watch: {
    selectedChannelIndex(val) {
      this.selectedChannel = this.channels[val]
    },
    channels:{
      deep: true,
      handler() {
        if (this.channels.length > 0){
          if(this.selectedChannel === null) {
            this.selectedChannel = this.channels[0]
          }
          else{
            this.selectedChannel = this.channels.find(c => c.id === this.selectedChannel.id)
          }
        }
      }
    },
    tenantId(val) {
      this.$store.dispatch('messaging/getChannels', { tenantId: val })

      this.$messageHub.connection.on('MessageCreated', (channelId, message) => {

        const channel = this.channels.find(c => c.id === channelId)
        if (channel) {
          channel.messages.push(message)
        }
      })
    },
    unreadMessageCount(){

        const channels = _.groupBy(this.unreadMessages, 'channelId')

        Object.keys(channels).map(channelId => {
          const channel = this.channels.find(c => c.id === channelId)
          if(channel){
            channel.unreadMessageCount = channels[channelId].length }
          }
        )

        this.channels.map(channel => {
          if (!channels[channel.id]) {
            channel.unreadMessageCount = 0
          }
        })
    }
  },
  mounted() {
    if (this.tenantId) {
      this.$store.dispatch('messaging/getChannels', { tenantId: this.tenantId })
    }
  },
  methods: {
    sendMessage() {
      const payload = {
        tenantId: this.tenantId,
        channelId: this.selectedChannel.id,
        message: { content: this.message }
      }

      this.$store.dispatch('messaging/sendMessage', payload)
      this.message = ''
      this.$vuetify.goTo(this.$refs.messagebottom)
    },
    getLastMessageDateTime(channel) {
      if (!channel.lastMessageDateTime) {
        return ''
      }
      return format.toTodaysTimeOrPastDate(channel.lastMessageDateTime)
    },
    onChannelAdded(channel) {
      if(channel.id){
        this.$router.push({ name: 'messaging-channel-detail', params: { channelId: channel.id } })
      }
    }
  }
}
</script>

<style>
.frame {
  overflow-y: auto;
}

.frame::-webkit-scrollbar {
  -webkit-appearance: none;
}

.frame::-webkit-scrollbar:vertical {
  width: 11px;
}

.frame::-webkit-scrollbar:horizontal {
  height: 11px;
}

.frame::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 2px solid white; /* should match background, can't be transparent */
  background-color: rgba(0, 0, 0, 0.5);
}
</style>

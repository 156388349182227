import { fileUtils } from '@/services/file-utils.service.js'
import apiClient from '@/services/api/clients/api-client.js'
import configService from '@/services/config.service'

const build = function(tenantId) {

  const path = `${configService.config.apiUrl}/${tenantId}/media`
  const multipartPath = 'multipart';
  const client = apiClient.build(path)

  client.meta = (id) => client.get(`${path}/${id}/meta`);
  client.search = (search) => client.postPath('search', search);
  client.urls = (request) => client.postPath('urls', request);
  client.abortMultiPartUpload = (id) => client.postPath(`${multipartPath}/${id}/cancel`, null);

  client.upload = (file, request) => {
    const formData = new FormData();
    formData.append('file', file);

    if(request.reference){
      formData.append('reference.entityId', request.reference.entityId);
      formData.append('reference.entityType', request.reference.entityType);
      formData.append('reference.order', request.reference.order ?? 0);

      if(request.reference.properties){
        Object.keys(request.reference.properties).forEach(key => {
          formData.append(`reference.properties.${key}`, request.reference.properties[key]);
        });
      }
    }

    if(request.attributes){
      Object.keys(request.attributes).forEach(key => {
        formData.append(`attributes.${key}`, request.attributes[key]);
      });
    }
    return client.post(formData);
  }

/*
MultiPart Upload
*/
const raiseEvent = (event, onProgress) => {
  if (typeof onProgress == 'function') {
    onProgress({ parts: 0, uploaded: 0, ...event })
  }
}
client.uploadMultiPart = (request, file, options) => {

  const uploadRequest = {
    ...request,
    fileName: file.name,
    fileSize: file.size,
    contentType: file.type || 'application/octet-stream'
  }

  console.log('uploadMultiPart uploadRequest', { uploadRequest })

  if (file.size < fileUtils.minMultipartUploadPartSizeBytes) {
    console.log(
      'File is too small for multipart upload.  Uploading file directly'
    )
    return client.upload(file, request)
  }

  return new Promise((resolve, reject) => {
    try {
      const { onProgress, onComplete, onStartMultipart } = options || {}
      raiseEvent({ msg: 'Starting multipart upload' }, onStartMultipart)

      const reader = new FileReader()

      reader.onload = async () => {
        try {
          const arrayBuffer = reader.result

          // 1. Create multipart upload
          raiseEvent({ msg: 'Creating multipart upload' }, onProgress)

          const media = await createMultipartUpload(uploadRequest, options)
          console.log('createResponse', { media })

          raiseEvent({ msg: 'Created multipart upload' }, onProgress)

          // 2. Upload parts
          const parts = fileUtils.splitArrayBuffer(
            arrayBuffer,
            fileUtils.minMultipartUploadPartSizeBytes
          )

          raiseEvent(
            { msg: 'Uploading parts', parts: parts.length },
            onProgress
          )

          for (const part of parts) {
            const uploadPartRequest = {
              id: media.id,
              partNumber: parts.indexOf(part) + 1,
              bytes: part
            }

            raiseEvent(
              {
                msg: `Uploading part ${uploadPartRequest.partNumber}`,
                parts: parts.length,
                uploaded: uploadPartRequest.partNumber - 1
              },
              onProgress
            )

            await uploadPart(uploadPartRequest, options)

            raiseEvent(
              {
                msg: 'Part uploaded',
                parts: parts.length,
                uploaded: uploadPartRequest.partNumber
              },
              onProgress
            )
          }

          // 3. Complete multipart upload
          const completeResponse = await completeMultipartUpload(
            media.id,
            options
          )

          raiseEvent(
            {
              msg: 'Completed multipart upload',
              parts: parts.length,
              uploaded: parts.length
            },
            onComplete
          )
          console.log('mediaitemapi: completeResponse', completeResponse)
          resolve(completeResponse)
        } catch (error) {
          reject(error)
        }
      }

      console.log('reading file')
      reader.readAsArrayBuffer(file)
    } catch (error) {
      console.log('multipart upload error', error)
      reject(error)
    }
  })
}

const createMultipartUpload = async (request) => {
  console.log('createMultipartUpload', {multipartPath, request })
  return await client
    .postPath(multipartPath, request)
    .then(response => response.data)
}

const uploadPart = (request) => {
  const uploadPath = `${multipartPath}/${request.id}/part/${request.partNumber}`
  const blob = new Blob([request.bytes], { type: 'application/octet-stream' })

  return client
    .postPath(uploadPath, blob, {
      headers: {
        'Content-Type': 'application/octet-stream'
      }
    })
    .then(response => response.data)
}

const completeMultipartUpload = (id) => {
  const completePath = `${multipartPath}/${id}/complete`
  return client.postPath(completePath, null).then(response => response.data)
}

client.abortMultipartUpload = (id,) => {
  const abortPath = `${multipartPath}/${id}/cancel`
  return client.postPath(abortPath, null).then(response => response.data)
}

  return client
}

export default { build };
